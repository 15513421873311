import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const LogoDisplay = ({
  color,
  children,
  darker,
  image,
  imageLast,
  title,
}) => {
  return (
    <section
      className={`text-white`}
    >
      <div>
          <div className="w-full content-center">
            {image && <Img fluid={image.childImageSharp.fluid} />}
        </div>
      </div>
    </section>
  );
};
LogoDisplay.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
  darker: PropTypes.bool,
  image: PropTypes.any,
  imageLast: PropTypes.bool,
  title: PropTypes.string,
};
LogoDisplay.defaultProps = {
  color: '',
  children: null,
  darker: false,
  image: null,
  imageLast: false,
  title: '',
};

export default LogoDisplay;
