import React from 'react';
import PropTypes from 'prop-types';
import MapsIcon from '../assets/svg/icon.svg';


const Logo = ({ className }) => {
  return (
    <div className={`flex flex-col items-center ${className}`}>
<h1 className="text-white vivo-c pt-20">
        Who’s ready to Vibe with Vivo Vibe!
      </h1>
        <div class="text-white pl-20 pr-20">
        <p class="pl-20 pr-20">You’ve come to the right place! A friend most likely referred you to this page to give you an idea of what they’re part of. Vivo Vibe is about living a vibrant life full of adventure, accomplishment, service and education. You’ll be part of a community who drive positive change. Connect with us and we’ll show you how to grow as an individual, as a health advocate, grow a massive business and grow your influence all while traveling and having fun. This site will provide everything you need to be a vibrant success! The first step is making a connection. Vibe with us!</p>
</div>
    </div>
  );
};

Logo.propTypes = { className: PropTypes.string };
Logo.defaultProps = { className: '' };

export default Logo;
